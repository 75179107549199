import {
  AppShell,
  Burger,
  Container,
  Flex,
  Header,
  Image,
  MediaQuery,
  Navbar,
  Paper,
  SimpleGrid,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Fragment, useState } from "react";

function Navigation() {
  return null;

/*   return (
    <>
      <NavLink label={"Hallo"} />
      <NavLink label={"Impressum"} />
      <NavLink label={"Kontakt"} />
    </>
  ); */
}

export default function AppShellDemo() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const smallPage = useMediaQuery("(max-width: 767px)");

  return (
    <AppShell
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.gray[3], overflow: "hidden" },
      })}
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
                hidden
              />
            </MediaQuery>

            <Title order={2}>Seniorentagespflege Burgblick</Title>

            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Navbar p="md" hidden={!opened}>
                <Navigation />
              </Navbar>
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Flex direction={"row"}>
                <Navigation />
              </Flex>
            </MediaQuery>
          </div>
        </Header>
      }
    >
      <Container h={"100%"}>
        <Paper radius={0} p={"100px"} h={"100%"}>
          <SimpleGrid cols={smallPage ? 1 : 2}>
            {data
              .sort((a, b) =>
                smallPage
                  ? a.smallOrder - b.smallOrder
                  : a.bigOrder - b.bigOrder
              )
              .map((x, index) => (
                <Fragment key={index}>{x.element}</Fragment>
              ))}
          </SimpleGrid>
        </Paper>
      </Container>
    </AppShell>
  );
}

const data = [
  {
    bigOrder: 2,
    smallOrder: 2,
    element: (
      <Image
        maw={340}
        mx="auto"
        radius="md"
        src="./logo.jpg"
        alt="Random image"
      />
    ),
  },
  {
    bigOrder: 2,
    smallOrder: 2,
    element: (
      <Text>
        Da wo andere Urlaub machen, verbringen Sie einen oder mehrere Tage in
        der Seniorentagespflege. Hol- und Bringservice in modernen Bussen,
        Frühstück, Mittagessen, Kaffee und Kuchen inclusive. Die gesamte
        Einrichtung ist ebenerdig und barrierefrei.
      </Text>
    ),
  },
  {
    bigOrder: 3,
    smallOrder: 4,
    element: (
      <Text>
        Der große Gemeinschaftsraum, drei Ruheräume und 2 Pflegebäder schaffen
        eine angenehme Atmosphäre mit Wohlfühlcharakter. Unsere schöne
        Außenanlage im Zeichen der Burg, lädt zum Sonnenbad oder relaxen im
        Schatten der Kastanie ein und der Rundgang zur Erhaltung der Fitness.
      </Text>
    ),
  },
  {
    bigOrder: 4,
    smallOrder: 3,
    element: (
      <Image
        maw={340}
        mx="auto"
        radius="md"
        src="./home-1.jpg"
        alt="Random image"
      />
    ),
  },
  {
    bigOrder: 5,
    smallOrder: 5,
    element: (
      <Image
        maw={340}
        mx="auto"
        radius="md"
        src="./home-2.jpg"
        alt="Random image"
      />
    ),
  },
  {
    bigOrder: 6,
    smallOrder: 6,
    element: (
      <Text>
        Das hochqualifizierte Team betreut den Tagesgast mit Feingefühl und
        Respekt. Die verschiedenen Beschäftigungsangebote lassen keine
        Langeweile aufkommen. Die Ressourcen der Tagesgäste werden individuell
        auf sie abgestimmt. Das Steckenpferd der Einrichtung ist die
        Kreativarbeit, dabei entstehen jeden Tag neue Kunstwerke.
      </Text>
    ),
  },
];
